<template lang="pug">
  .block
    OtaHeader(:validator="validator")
    .sections
      PlanName(:object="validator.plan_name")
      BasicPrice(
        :object="validator.basic_price"
        :applied-changes="validator.$model.applied_changes"
        :optional-export-status="validator.$model.optional_export_status"
        :included-export-status="validator.$model.included_export_status"
      )
      CancellationFee(:object="validator.sources_cancellation_fee_rule")
      SelectableOptions(:object="validator.car_options")
      Insurance(:object="validator.insurances")
      PlanDescription(:object="validator.description")
      ReservationAvailability(:object="validator.availability_period")
      CancellationPeriod(:object="validator.cancellation_period")
      Points(:object="validator.points")
</template>

<script>
  export default {
    props: {
      validator: Object
    },

    components: {
      PlanName: () => import("./PlanName"),
      BasicPrice: () => import("./BasicPrice"),
      CancellationFee: () => import("./CancellationFee"),
      SelectableOptions: () => import("./SelectableOptions"),
      Insurance: () => import("./Insurance"),
      PlanDescription: () => import("./PlanDescription"),
      ReservationAvailability: () => import("./ReservationAvailability"),
      CancellationPeriod: () => import("./CancellationPeriod"),
      Points: () => import("./Points"),
      OtaHeader: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/OtaHeader"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .block
    +block

    .sections
      +sections
</style>
